//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { miceService } from '@/service/miceService'
	export default {
		data() {
			return {
				addressForm: {
					province: [],
					city: []
				},
				provinceList: [],
				cityList: [],
			}
		},
		created() {
			this.getProvinceList();
		},
		watch: {
			["addressForm.province"]() {
				if(this.addressForm.province.length > 0) {
					let _provinceForm = this.provinceList.filter((ele) => {
						return ele.txt == this.addressForm.province[0];
					})[0];
					//缓存当前省份信息
					this.$store.commit('SETPROVINCEFORM', _provinceForm)
					//获取城市
					let params = {
						regionId: _provinceForm.regionId,
						type: 2
					}
					this.getCityList(params);
					//其他很多组件用到了sessionStorage，为了避免影响之前的逻辑，这里也保存一遍......TODO
					sessionStorage.setItem("province", _provinceForm.txt)
					sessionStorage.setItem("provinceId", _provinceForm.regionId)
				}
			},
			["addressForm.city"]() {
				if(this.addressForm.city.length > 0) {
					let _cityForm = this.cityList.filter((ele) => {
						return ele.txt == this.addressForm.city[0];
					})[0];
					//缓存当前城市信息
					this.$store.commit('SETCITYFORM', _cityForm);
					//其他很多组件用到了sessionStorage，为了避免影响之前的逻辑，这里也保存一遍......TODO
					sessionStorage.setItem("city", _cityForm.txt)
					//特别注意:1、查询医院用cityId == regionId，2、查询餐厅cityId == pingRegionId
					sessionStorage.setItem("cityId", _cityForm.regionId)
					sessionStorage.setItem("pingRegionId", _cityForm.pingRegionId)
				}
			}
		},
		filters: {
			provinceFilter(list) {
				if(list.length == 0) {
					return [];
				}
				return [list.map((ele) => {
					return ele.txt;
				})]
			},
			cityFilter(list) {
				if(list.length == 0) {
					return [];
				}
				return [list.map((ele) => {
					return ele.txt;
				})]
			}
		},
		methods: {
			//获取省份
			async getProvinceList() {
				let params = {
					regionId: "945e4101-78b8-11e6-b6c0-f80f41fdc7f8",
					type: 1
				}
				let response = await miceService.getCityList(params)
				this.provinceList = response.content;
				if(this.provinceList.length > 0) {
					this.addressForm.province = this.$store.state.ishop.provinceForm.txt ? [this.$store.state.ishop.provinceForm.txt] : [];
				}
			},
			async getCityList(params) {
				let response = await miceService.getCityList(params)
				this.cityList = response.content;
				if(this.cityList.length > 0) {
					this.addressForm.city = this.$store.state.ishop.cityForm.txt ? [this.$store.state.ishop.cityForm.txt] : [];
				}
			},
			changeProvince(value) {
				//手动切换省份清空所有城市信息，重新选择
				if(this.$store.state.ishop.provinceForm.txt && value[0] != this.$store.state.ishop.provinceForm.txt) {
					this.addressForm.city = [];
					this.$store.commit('SETCITYFORM', false);
					//其他很多组件用到了sessionStorage，为了避免影响之前的逻辑，这里也保存一遍......TODO
					sessionStorage.removeItem("city")
					sessionStorage.removeItem("cityId")
					sessionStorage.removeItem("pingRegionId")
					sessionStorage.removeItem("hospital")
					sessionStorage.removeItem("hospitalId");
				}
			},
			//返回
			async goBack() {
				this.$store.commit('SETPROVINCEFORM', false);
				this.$store.commit('SETCITYFORM', false);
				if(sessionStorage.getItem('appSource') && sessionStorage.getItem('appSource') == 'ievent'){
					if(window.__wxjs_environment === "miniprogram"){
						const sessionId = await this.saveCacheData();
        				let ievent = `${this.iStorage.get("Uris").Uris["webapp-ievent"]}/Home/More?&rad=${sessionId}&rnd=${new Date().getTime()}`
						window.open(ievent, "_self");
						return
					}
					this.iStorage.clear()
            		this.iJsBridge.close();
				}else{
					this.$router.push('/')
				}
			},
			// 业务数据存入mangodb
			async saveCacheData() {
				const cacheData = {
				userId: this.$cookies.getCookie("userId"),
				role_codes: this.$cookies.getCookie("role_codes"),
				tenant_code: this.$cookies.getCookie("tenant_code"),
				token: this.$cookies.getCookie("token"),
				container: this.$cookies.getCookie("container") || 'h5',
				tenant_id: this.$cookies.getCookie("tenant_id"),
				platform: this.$cookies.getCookie("platform"),
				};
				const params = {
				Data: cacheData,
				CacheEntryOptions: {
					AbsoluteExpirationRelativeToNow: "08:00:00", //从现在开始计时(时:分:秒）过期
				},
				};
				const res = await miceService.saveCacheData(params);
				return res.content || "";
			},
			//确定
			confirm() {
				for(var key in this.addressForm) {
					if(this.addressForm[key].length < 1) {
						this.toast({
							message: '省市不能为空',
							duration: 1500
						});
						return false;
					}
				}
				this.$router.push({
					path: '/chooseType'
				})
			}
		}
	}
